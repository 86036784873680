import {Validation, type ValidationConfig, type ValidationResponse, WarningLevel} from "./validations";
import mime from "mime-lite";
import type {InputElement} from "../components/inputElement";
import type {InputType} from "../commons";
import type {UploadData} from "../components/uploadField";

export type SupportedFileTypesConfig = ValidationConfig<{
    id: string;
    types: string;
}>;

export class SupportedFileTypes extends Validation {

    private types: string[];

    private constructor(messageText: string, onlyWarning: WarningLevel, supportedTypes: string) {
        super(messageText, onlyWarning);
        this.types = supportedTypes.split(",").map(s => s.trim());
    }

    public static from(config: SupportedFileTypesConfig): SupportedFileTypes {
        return new SupportedFileTypes(config.message, config.warningLevel, config.validation.types);
    }

    protected valid(files: UploadData[] | null): boolean {
        return !files || files.length === 0
            || files.every(file => this.fileValid(file));
    }

    private fileValid(file: UploadData): boolean {
        return this.types.some(type => file.type === mime.getType(type));
    }

    public accept<T extends InputType>(inputElement: InputElement<T>, value?: T): void {
        const validationResponse: ValidationResponse = this.checkValidity(value);

        if (!validationResponse.valid) {
            inputElement.addMessage({warningLevel: validationResponse.warningLevel, message: this.errorText});
        }
    }
}