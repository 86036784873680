import {customElement, property} from "lit/decorators.js";
import {formSubscriptionEvent} from "../formEvents";
import {UnLitElement} from "../../../common/elements";

@customElement("eop-conditional-element")
export class EopConditionalElement extends UnLitElement {

    @property({attribute: "condition"})
    private condition: string;

    public connectedCallback(): void {
        super.connectedCallback();
        if (this.condition) {
            this.dispatchEvent(formSubscriptionEvent(this.condition, result => this.changeCondition(result)));
        }
    }

    private changeCondition(result: boolean): void {
        this.hidden = !result;
    }
}