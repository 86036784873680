import {Validation, type ValidationConfig, type ValidationResponse, WarningLevel} from "./validations";
import type {InputType} from "../commons";
import type {InputElement} from "../components/inputElement";
import type {UploadData} from "../components/uploadField";

export type FileSizeConfig = ValidationConfig<{
    id: string;
    maxSize: string;
}>;

export class MaxUploadSize extends Validation {

    private constructor(messageText: string, onlyWarning: WarningLevel, private maxSize: string) {
        super(messageText, onlyWarning);
    }

    public static from(config: FileSizeConfig): MaxUploadSize {
        return new MaxUploadSize(config.message, config.warningLevel, config.validation.maxSize);
    }

    protected valid(files: UploadData[]): boolean {
        return !files || files.length === 0
            || files.every(file => this.fileValid(file));
    }

    private fileValid(file: UploadData): boolean {
        return file.size <= this.maxSizeInByte();
    }

    private maxSizeInByte(): number {
        let size: number;
        if (this.maxSize.endsWith("MB")) {
            size = parseInt(this.maxSize.slice(0, -2)) * 1024 * 1024;
        } else if (this.maxSize.endsWith("kB")) {
            size = parseInt(this.maxSize.slice(0, -2)) * 1024;
        } else {
            size = parseInt(this.maxSize);
        }
        return size >= 0 ? size : 0;
    }

    public accept<T extends InputType>(inputElement: InputElement<T>, value?: T): void {
        const validationResponse: ValidationResponse = this.checkValidity(value);

        if (!validationResponse.valid) {
            inputElement.addMessage({warningLevel: validationResponse.warningLevel, message: this.errorText});
        }
    }
}