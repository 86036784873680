import {type DefaultValidationConfig, InputElementType, Validation, type ValidationConfig, type ValidationResponse, WarningLevel} from "./validations";
import type {InputType} from "../commons";
import type {InputElement} from "../components/inputElement";

const EMAIL_SPLIT_PATTERN = new RegExp(/^([^@]+)@([^@]+)$/);
const EMAIL_LOCAL_PATTERN = new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+$/);
const EMAIL_DOMAIN_PATTERN = new RegExp(/^([a-zA-Z0-9-]+\.)+[a-zA-Z0-9-]{2,}$/);

export class Email extends Validation {

    private constructor(messageText: string, onlyWarning: WarningLevel) {
        super(messageText, onlyWarning);
    }

    public static from(config: ValidationConfig<DefaultValidationConfig>): Email {
        return new Email(config.message, config.warningLevel);
    }

    protected valid(value: unknown): boolean {
        if (typeof value !== "string") {
            return false;
        } else if (value === "") {
            return true;
        }

        const parts = EMAIL_SPLIT_PATTERN.exec(value);
        if (parts === null) {
            return false;
        }
        const [, localPart, domain] = parts;

        return this.validLocalPart(localPart)
            && this.validDomain(domain);

    }

    private validLocalPart(localPart: string): boolean {
        if (localPart.startsWith(".") || localPart.endsWith(".") || localPart.includes("..")) {
            return false;
        }
        return EMAIL_LOCAL_PATTERN.test(localPart);
    }

    private validDomain(domain: string): boolean {
        if (domain.startsWith("-") || domain.includes("-.") || domain.includes(".-") || domain.endsWith("-")) {
            return false;
        }
        return EMAIL_DOMAIN_PATTERN.test(domain);
    }

    public accept<T extends InputType>(inputElement: InputElement<T>, value?: T): void {
        inputElement.setType(InputElementType.EMAIL);
        const validationResponse: ValidationResponse = this.checkValidity(value);

        if (!validationResponse.valid) {
            inputElement.addMessage({warningLevel: validationResponse.warningLevel, message: this.errorText});
        }
    }
}