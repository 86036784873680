import {Required} from "./required";
import {Email} from "./email";
import {PostalCode} from "./postalCode";
import {PhoneNumber} from "./phoneNumber";
import {MaxLength, type MaxLengthConfig} from "./maxLength";
import {DateRange} from "./dateRange";
import {Optional} from "./optional";
import type {Validation, ValidationConfig} from "./validations";
import {type FileSizeConfig, MaxUploadSize} from "./maxUploadSize";
import {SupportedFileTypes, type SupportedFileTypesConfig} from "./supportedFileTypes";
import {MinAge} from "./minAge";
import {PermittedValues, type PermittedValuesConfig} from "./permittedValues";
import {type FileCountConfig, MaxUploads} from "./maxUploads";

export namespace ValidationFactory {
    // eslint-disable-next-line complexity
    export function from(config: ValidationConfig<any>): Validation {
        switch (config.validation?.id) {
            case "required":
                return Required.from(config);
            case "dateRange":
                return DateRange.from(config);
            case "minAge":
                return MinAge.from(config);
            case "email":
                return Email.from(config);
            case "postalCode":
                return PostalCode.from(config);
            case "phoneNumber":
                return PhoneNumber.from(config);
            case "maxLength":
                return MaxLength.from(config as MaxLengthConfig);
            case "maxUploadSize":
                return MaxUploadSize.from(config as FileSizeConfig);
            case "maxUploads":
                return MaxUploads.from(config as FileCountConfig);
            case "supportedFileTypes":
                return SupportedFileTypes.from(config as SupportedFileTypesConfig);
            case "permittedValues":
                return PermittedValues.from(config as PermittedValuesConfig);
            default:
                return new Optional();
        }
    }
}
