import {type DefaultValidationConfig, Validation, type ValidationConfig, type ValidationResponse, WarningLevel} from "./validations";
import type {InputType} from "../commons";
import type {InputElement} from "../components/inputElement";

export class Required extends Validation {

    private constructor(messageText: string, onlyWarning: WarningLevel) {
        super(messageText, onlyWarning);
    }

    public static from(config: ValidationConfig<DefaultValidationConfig>): Required {
        return new Required(config.message, config.warningLevel);
    }

    protected valid(value: unknown): boolean {
        return !!value;
    }

    public accept<T extends InputType>(inputElement: InputElement<T>, value?: T): void {
        inputElement.setRequired(true);
        const validationResponse: ValidationResponse = this.checkValidity(value);

        if (!validationResponse.valid) {
            inputElement.addMessage({warningLevel: validationResponse.warningLevel, message: this.errorText});
        }
    }
}