import {Validation, type ValidationConfig, type ValidationResponse, WarningLevel} from "./validations";
import type {InputType} from "../commons";
import type {InputElement} from "../components/inputElement";

export type MaxLengthConfig = ValidationConfig<{
    id: string;
    maxLength: number;
}>;

export class MaxLength extends Validation {

    private constructor(messageText: string, onlyWarning: WarningLevel, private maxLength: number) {
        super(messageText, onlyWarning);
    }

    public static from(config: MaxLengthConfig): MaxLength {
        return new MaxLength(config.message, config.warningLevel, config.validation.maxLength);
    }

    protected valid(value: unknown): boolean {
        return typeof value === "string" && value.length <= this.maxLength;
    }

    public accept<T extends InputType>(inputElement: InputElement<T>, value?: T): void {
        const validationResponse: ValidationResponse = this.checkValidity(value);

        if (!validationResponse.valid) {
            inputElement.addMessage({warningLevel: validationResponse.warningLevel, message: this.errorText});
        }
    }

}