import type {InputElement} from "../components/inputElement";
import type {InputType} from "../commons";

export abstract class Validation {
    protected constructor(
        public readonly errorText: string,
        protected warningLevel: WarningLevel
    ) {
    }

    public checkValidity(value: unknown): ValidationResponse {
        return {valid: this.valid(value), warningLevel: this.warningLevel};
    }

    protected abstract valid(value: unknown): boolean;

    public abstract accept<T extends InputType>(inputElement: InputElement<T>, value?: T): void ;
}

export type ValidationResponse = {
    valid: boolean;
    warningLevel: WarningLevel;
};

export enum WarningLevel {
    WARNING = "warning",
    ERROR = "error"
}

export class ValidationError extends Error {

    public constructor(public invalidElement: InputElement<any>) {
        super("validation error");
    }

}

export enum InputElementType {
    NUMBER = "number",
    TEXT = "text",
    EMAIL = "email",
    PHONE = "tel",
    DATE = "date"
}

export type ValidationConfig<T extends DefaultValidationConfig> = {
    validation: T;
    message: string;
    warningLevel: WarningLevel;
    type?: string;
};

export type DefaultValidationConfig = {
    id: string;
};