import {InputElement} from "./inputElement";
import type {TemplateResult} from "lit";
import {html} from "lit";
import Styles from "./checkbox.lit.scss";
import {customElement, property, query} from "lit/decorators.js";
import {elementFrom} from "../../../common/utils/html";
import {unsafeHTML} from "lit/directives/unsafe-html.js";
import {Dictionary} from "../../../page/elements/dictionary";
import CHECK_ICON from "../../../../../resources/assets/images/check_tick.svg";

@customElement("eop-checkbox")
export class EopCheckbox extends InputElement<boolean> {

    public static readonly styles = Styles;

    @property({attribute: "label"})
    private label: string;
    @property({attribute: "label-check"})
    private labelCheck: string;
    @property({attribute: "label-uncheck"})
    private labelUncheck: string;
    @property({attribute: "preselected", type: Boolean})
    private preselected: boolean;
    @query("input")
    private input: HTMLInputElement;

    private dictionary: Dictionary;

    public constructor() {
        super(false);
        this.dictionary = Dictionary.of(this);
    }

    public render(): TemplateResult {
        return html`
            <div class="input-element ${this.basicClassMap()}">
                <label>
                    <input class="box"
                           type="checkbox"
                           id=${(this.getFullId())}
                           ?checked=${this.value}
                           ?required=${this.required}
                           @input=${this.toggle}
                    >
                    <span class="box">${elementFrom(CHECK_ICON)}</span>
                    <span class="checkbox-label">${unsafeHTML(this.label)}</span>
                </label>
                ${this.renderValidationIcon()}
            </div>
            ${this.renderValidationMessages()}
        `;
    }

    private toggle(event: Event): void {
        const input = event.currentTarget as HTMLInputElement;
        this.updateValue(input.checked);
    }

    protected preset(): boolean {
        return this.preselected;
    }

    protected valueEnhancements(inputValue: boolean): Map<string, string> {
        const map = new Map();
        const label = inputValue
            ? this.labelCheck
            : this.labelUncheck;
        map.set("label", label ?? this.dictionary.translate("MSG_CHECKED", inputValue.toString()) + ": " + this.label);
        return map;
    }

    public focusInput(): void {
        this.input.focus();
    }
}