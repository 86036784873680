import {SessionStorage} from "../../common/clientStorage";
import {autoRegister, resolve} from "../../container";
import {newUUID} from "../../common/uuid";

export const SESSION_ID_KEY = "form-session-id-";

@autoRegister()
export class FormSessionId {

    public constructor(private sessionStorage: SessionStorage = resolve(SessionStorage)) {
    }

    public get(id: string): string {
        const currentSessionId = this.sessionStorage.fetch<string>(this.computeKey(id));
        if (currentSessionId) {
            return currentSessionId;
        }
        const sessionId = "session-" + newUUID();
        this.sessionStorage.put(this.computeKey(id), sessionId);
        return sessionId;
    }

    public clear(id: string): void {
        this.sessionStorage.remove(this.computeKey(id));
    }

    private computeKey(id: string): string {
        return SESSION_ID_KEY + id;
    }
}