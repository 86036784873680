import {type ActionResponse, ActionService, MissionOneMidRequest} from "./actionService";
import {QueryParameters} from "../../../common/queryParameters";
import {resolve} from "../../../container";
import {EOP_ERROR_HANDLER, Promises} from "../../../common/utils/promises";

const MID_PARAM_NAME = "mid";
const CONFIRMED_STATE_LABEL = "confirmed";
const ERROR_STATE_LABEL = "error";

abstract class MissionOneMidAction {
    public constructor(
        private element: Element,
        private spinnerId: string,
        protected actionService: ActionService = resolve(ActionService),
        private promises: Promises = resolve(Promises),
        private queryParameters: QueryParameters = resolve(QueryParameters)) {
    }

    public execute(): void {
        const mid = this.queryParameters.getString(MID_PARAM_NAME);

        if (!mid || mid === "" || mid === ERROR_STATE_LABEL) {
            this.showErrorText();
            return;
        }
        if (mid === CONFIRMED_STATE_LABEL) {
            this.showConfirmedText();
            return;
        }

        this.promises.decoratorFor(this.spinnerId)(this.executeAction(new MissionOneMidRequest(mid))
            .then((response: ActionResponse) => {
                if (response.success) {
                    this.showConfirmText();
                    this.queryParameters.set(MID_PARAM_NAME, CONFIRMED_STATE_LABEL);
                } else {
                    this.showErrorText();
                    this.queryParameters.set(MID_PARAM_NAME, ERROR_STATE_LABEL);
                }
            }).catch((error) => {
                EOP_ERROR_HANDLER(error, () => {
                    this.showErrorText();
                    this.queryParameters.set(MID_PARAM_NAME, ERROR_STATE_LABEL);
                });
            }));
    }

    protected abstract executeAction(request: MissionOneMidRequest): Promise<ActionResponse>;

    private showConfirmText(): void {
        this.element.querySelector(".confirm-text")?.show();
    }

    private showConfirmedText(): void {
        this.element.querySelector(".confirmed-text")?.show();
    }

    private showErrorText(): void {
        this.element.querySelector(".error-text")?.show();
    }
}

class MissionOneConfirmSubscriberAction extends MissionOneMidAction {
    public executeAction(request: MissionOneMidRequest): Promise<ActionResponse> {
        return this.actionService.confirmMissionOneSubscriber(request);
    }
}

class MissionOneDeleteSubscriberAction extends MissionOneMidAction {
    public executeAction(request: MissionOneMidRequest): Promise<ActionResponse> {
        return this.actionService.deleteMissionOneSubscriber(request);
    }
}

export class EopConfirmMissionOneSubscriber extends HTMLElement {

    public constructor() {
        super();
    }

    public connectedCallback(): void {
        new MissionOneConfirmSubscriberAction(this, this.getAttribute("spinner-id") ?? "")
            .execute();
    }
}

export class EopDeleteMissionOneSubscriber extends HTMLElement {

    public constructor() {
        super();
    }

    public connectedCallback(): void {
        new MissionOneDeleteSubscriberAction(this, this.getAttribute("spinner-id") ?? "")
            .execute();
    }
}

customElements.define("eop-confirm-mission-one-subscriber", EopConfirmMissionOneSubscriber);
customElements.define("eop-delete-mission-one-subscriber", EopDeleteMissionOneSubscriber);