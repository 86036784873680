import {Validation, type ValidationConfig, type ValidationResponse, WarningLevel} from "./validations";
import type {InputType} from "../commons";
import type {InputElement} from "../components/inputElement";
import type {UploadData} from "../components/uploadField";

export type FileCountConfig = ValidationConfig<{
    id: string;
    maxUploads: number;
}>;

export class MaxUploads extends Validation {
    private constructor(messageText: string, onlyWarning: WarningLevel, private maxUploads: number) {
        super(messageText, onlyWarning);
    }

    public static from(config: FileCountConfig): MaxUploads {
        return new MaxUploads(config.message, config.warningLevel, config.validation.maxUploads);
    }

    protected valid(files: UploadData[]): boolean {
        return !files || files.length <= this.maxUploads;
    }

    public accept<T extends InputType>(inputElement: InputElement<T>, value?: T): void {
        const validationResponse: ValidationResponse = this.checkValidity(value);

        if (!validationResponse.valid) {
            inputElement.addMessage({warningLevel: validationResponse.warningLevel, message: this.errorText});
        }
    }
}