import {eopCustomEvent} from "../../common/utils/events";
import type {FormPathAggregator, InputType} from "./commons";
import type {FormSubscription} from "./subscriptions";
import type {StepData} from "./formStep";

export const INPUT_ELEMENT_EVENT = "eop-input-element";
export const INPUT_ELEMENT_INIT_EVENT = "eop-input-element-init";
export const FORM_CONTROL_EVENT = "eop-form-control";
export const DATA_SUBSCRIBE_EVENT = "eop-data-subscribe";
export const STEP_INIT_EVENT = "eop-step-init";
export const DELETE_UPLOAD_EVENT = "eop-delete-upload";
export const DRAG_DROP_UPLOAD_EVENT = "eop-drag-drop-upload";

export type FormEvent<T extends InputType> = CustomEvent<FormEventData<T>>;

export type FormEventData<T extends InputType> = { path: string[]; inputValue: T; customValues: Map<string, string> };

export type FormInitEvent = CustomEvent<FormInitEventData>;

export type FormInitEventData = { path: FormPathAggregator; validate: (silent?: boolean) => void };

export type FormControlEvent = CustomEvent<FormControlEventData>;

export type FormControlEventData = {};

export type FormSubscriptionEvent = CustomEvent<FormSubscriptionData>;

export type FormSubscriptionData = { condition: string; subscription: FormSubscription };

export type FormStepInitEvent = CustomEvent<FormStepEventData>;

export type FormStepEventData = { step: StepData };

export type FormDeleteUploadEvent = CustomEvent<FormDeleteUploadData>;

export type FormDeleteUploadData = { fileKey: string };

export type FormDragDropUploadEvent = CustomEvent<FormDragDropUploadData>;

export type FormDragDropUploadData = { inputFiles: File[] };

export function inputElementEvent<T extends InputType>(path: string[], inputValue: T, customValues?: Map<string, string>): FormEvent<T> {
    return eopCustomEvent(INPUT_ELEMENT_EVENT, {path: path, inputValue: inputValue, customValues: customValues ?? new Map()});
}

export function initInputElementEvent(pathAggregator: FormPathAggregator, validate: (silent?: boolean) => void): FormInitEvent {
    return eopCustomEvent(INPUT_ELEMENT_INIT_EVENT, {path: pathAggregator, validate: validate});
}

export function formControlEvent(): FormControlEvent {
    return eopCustomEvent(FORM_CONTROL_EVENT, {});
}

export function initStepEvent(stepData: StepData): FormStepInitEvent {
    return eopCustomEvent(STEP_INIT_EVENT, {step: stepData});
}

export function formSubscriptionEvent(condition: string, subscription: FormSubscription): FormSubscriptionEvent {
    return eopCustomEvent(DATA_SUBSCRIBE_EVENT, {condition: condition, subscription: subscription});
}

export function formDeleteUploadEvent(fileKey: string): FormDeleteUploadEvent {
    return eopCustomEvent(DELETE_UPLOAD_EVENT, {fileKey: fileKey});
}

export function formDragDropUploadEvent(inputFiles: File[]): FormDragDropUploadEvent {
    return eopCustomEvent(DRAG_DROP_UPLOAD_EVENT, {inputFiles: inputFiles});
}