import {type DefaultValidationConfig, Validation, type ValidationConfig, type ValidationResponse, WarningLevel} from "./validations";
import type {InputType} from "../commons";
import type {InputElement} from "../components/inputElement";

const POSTAL_CODE_PATTERN = new RegExp(/^\d{5}$/);

export class PostalCode extends Validation {

    private constructor(messageText: string, onlyWarning: WarningLevel) {
        super(messageText, onlyWarning);
    }

    public static from(config: ValidationConfig<DefaultValidationConfig>): PostalCode {
        return new PostalCode(config.message, config.warningLevel);
    }

    protected valid(value: unknown): boolean {
        return typeof value === "string"
            && (value === "" || POSTAL_CODE_PATTERN.test(value));
    }

    public accept<T extends InputType>(inputElement: InputElement<T>, value?: T): void {
        const validationResponse: ValidationResponse = this.checkValidity(value);

        if (!validationResponse.valid) {
            inputElement.addMessage({warningLevel: validationResponse.warningLevel, message: this.errorText});
        }
    }

}