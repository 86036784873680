import {customElement, property} from "lit/decorators.js";
import type {TemplateResult} from "lit";
import {html, LitElement} from "lit";
import Styles from "./uploadedFile.lit.scss";
import {formDeleteUploadEvent} from "../formEvents";
import {unsafeSVG} from "lit/directives/unsafe-svg.js";
import {Dictionary} from "../../../page/elements/dictionary";
import {LocatedNumbers} from "../../../common/utils/locatedNumbers";
import {resolve} from "../../../container";
import TRASH_ICON from "../../../../../resources/assets/images/trash.svg";


const KB_MULTIPLE = 1024;
const MB_MULTIPLE = KB_MULTIPLE * KB_MULTIPLE;

enum InputState {
    UPLOADING,
    UPLOADED
}

@customElement("eop-uploaded-file")
export class EopUploadedFile extends LitElement {

    public static readonly styles = Styles;

    @property({reflect: true})
    public key: string;

    public constructor(public name: string, public size: number, private locatedNumbers: LocatedNumbers = resolve(LocatedNumbers)) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.updateProgress(this.key ? 100 : 0);
    }

    public render(): TemplateResult {
        return html`
            <div class="file-row" data-eventelement="form-upload">
                ${this.renderFileInfo()}
                ${this.renderDeleteButton()}
            </div>
            ${this.renderProgressBar()}
        `;
    }

    public updateProgress(progress: number): void {
        const roundedProgress = Math.round(progress);
        this.style.setProperty("--progress", roundedProgress + "%");
    }

    private renderFileInfo(): TemplateResult {
        return html`
            <div class="file-name">${this.name} <span class="file-size">(${this.readableFileSize()})</span></div>`;
    }

    private renderProgressBar(): TemplateResult {
        return html`
            <div class="progress-bar"></div>`;
    }

    private renderDeleteButton(): TemplateResult | null {
        const deleteMsg = Dictionary.of(this).translate("DELETE_FILE");
        if (this.inputState() !== InputState.UPLOADED) {
            return null;
        }

        return html`
            <button type="button" class="delete" @click=${this.deleteFile} title=${deleteMsg} data-tracking-label="delete">
                ${this.renderTrashIcon()}
            </button>`;
    }

    private deleteFile(): void {
        this.dispatchEvent(formDeleteUploadEvent(this.key));
    }

    private inputState(): InputState {
        if (this.key) {
            return InputState.UPLOADED;
        }
        return InputState.UPLOADING;
    }

    private renderTrashIcon(): TemplateResult {
        return html`${unsafeSVG(TRASH_ICON)}`;
    }

    private readableFileSize(): string {
        if (this.size >= MB_MULTIPLE) {
            return this.locatedNumbers.formatNumberString((this.size / MB_MULTIPLE).toFixed(2)) + " MB";
        }
        return this.locatedNumbers.formatNumberString((this.size / KB_MULTIPLE).toFixed(2) + " KB");
    }
}