import {customElement, property, query} from "lit/decorators.js";
import {html, type TemplateResult} from "lit";
import Styles from "./selectField.lit.scss";
import {InputElement} from "../inputElement";
import {GLOBAL} from "../../../../common/globals";
import {SELECT_OPTION_EVENT, type SelectOptionEvent} from "./selectOption";
import {prepareEvents} from "../../../../common/utils/events";

import {done, fresh} from "../../../../common/lifetime";

export type DataSelectOption = {
    value: string;
    label: string;
    preset: boolean;
};

@customElement("eop-select-field")
export class EopSelectField extends InputElement<string> {

    public static readonly styles = Styles;

    @property({attribute: "label"})
    public label: string;
    @property({attribute: "placeholder"})
    public placeholder: string;
    @property({attribute: "options", type: Array})
    public options: DataSelectOption[] = [];
    @property({attribute: true, reflect: true, type: Boolean})
    private open: boolean = false;
    @query("select")
    private selectElement: HTMLSelectElement;
    @query(".custom-select")
    private customSelectElement: HTMLElement;
    @query(".custom-options")
    private customOptionsElement: HTMLElement;

    public constructor() {
        super("");
        this.addEventListener(SELECT_OPTION_EVENT, (event: Event) => {
            const eventDetails = (event as SelectOptionEvent).detail;
            this.select(eventDetails.value);
            this.close();
        });
    }

    public render(): TemplateResult {
        return html`
            <div class="input-element ${this.basicClassMap()}">
                <label>
                    <span class="label-text">${this.label}</span>
                    ${this.labelSuffix()}
                </label>
                <div class="select-element">
                    <select name=${this.getFullId()}
                            .value=${this.value}
                            @change=${this.change}
                            ?required=${this.required}
                    >
                        <option value="" ?selected=${!this.value}><span>${this.placeholder}</span></option>
                        ${this.renderNativeOptions()}
                    </select>
                    <div class="custom-select"
                         ?filled=${this.value}
                         @click=${this.toggle}
                         aria-hidden="true"
                    >
                        <div class="custom-select-label"><span>${this.selectedValueDescriptor()}</span></div>
                        <div class="custom-options">
                            <eop-select-option class="placeholder"
                                               .label=${this.placeholder}
                                               ?selected=${!this.value}
                                               .value=${""}
                            ></eop-select-option>
                            ${this.renderCustomOptions()}
                        </div>
                    </div>
                    ${this.renderValidationIcon()}
                </div>
            </div>
            ${this.renderValidationMessages()}
        `;
    }

    private renderCustomOptions(): TemplateResult[] {
        return this.options.map(option => html`
            <eop-select-option
                    .value=${option.value}
                    .label=${option.label}
                    ?selected=${this.value === option.value}
            ></eop-select-option>
        `);
    }

    private renderNativeOptions(): TemplateResult[] {
        return this.options.map(option => html`
            <option value=${option.value} ?selected=${this.value === option.value}>${option.label}</option>
        `);
    }

    public focusInput(): void {
        this.selectElement.focus();
    }

    protected preset(): string | undefined {
        return this.options
            .findFirst(option => option.preset)
            ?.value;
    }

    protected valueEnhancements(inputValue: string): Map<string, string> {
        return new Map([["label", this.descriptorForValue(inputValue)]]);
    }

    private toggle(event: Event): void {
        if (!this.open) {
            this.dropDown(event);
        } else {
            this.close();
        }
    }

    private dropDown(openingEvent: Event): void {
        this.open = true;
        prepareEvents(GLOBAL.bodyElement())
            .boundTo(fresh(this, "select"))
            .on("click", currentEvent => this.handleClickWhenOpened(currentEvent, openingEvent));
    }

    private handleClickWhenOpened(currentEvent: Event, openingEvent: Event): void {
        if (currentEvent !== openingEvent) {
            this.close();
        }
    }

    private close(): void {
        this.open = false;
        done(this, "select");
    }

    private selectedValueDescriptor(): string {
        return this.descriptorForValue(this.value);
    }

    private descriptorForValue(value: string): string {
        return this.options
                .findFirst(option => value === option.value)
                ?.label
            ?? this.placeholder
            ?? "";
    }

    private select(value: string): void {
        this.updateValue(value);
    }

    protected change(event: Event): void {
        const value = (event.target as HTMLSelectElement).value;
        this.select(value);
    }
}