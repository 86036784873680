import {autoRegister, resolve} from "../../container";
import {Configuration} from "../../common/config";
import {Load} from "../../common/load";
import {GLOBAL} from "../../common/globals";

export type SecuredRequestExecutorWindow = Window & {
    AwsWafIntegration?: {
        getToken: () => Promise<string>;
    };
}

@autoRegister()
export class SecuredRequestExecutor {
    private readonly wafIntegrationUrl?: string;
    private readonly window: SecuredRequestExecutorWindow;

    public constructor(
        configuration: Configuration = resolve(Configuration),
        private load: Load = resolve(Load)
    ) {
        this.wafIntegrationUrl = configuration.get("WAF_INTEGRATION_URL");
        this.window = GLOBAL.window() as SecuredRequestExecutorWindow;
    }

    public async execute(securedRequest: () => Promise<Response>): Promise<Response> {
        const response = await securedRequest();
        if (!this.wafIntegrationUrl) {
            return response;
        }
        if (this.isChallengeRequired(response)) {
            await this.load.script(`${this.wafIntegrationUrl}/challenge.js`);
            await this.window.AwsWafIntegration?.getToken();
            return await securedRequest();
        }
        return response;
    }

    private isChallengeRequired(response: Response): boolean {
        return response.status === 202 && response.headers.get("x-amzn-waf-action") === "challenge";
    };

}
