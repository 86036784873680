import {customElement, property} from "lit/decorators.js";
import {InputElement} from "./inputElement";
import {html, type TemplateResult} from "lit";
import Styles from "./toggleLink.lit.scss";
import {noop} from "../../../common/utils/functions";

@customElement("eop-toggle-link")
export class EopToggleLink extends InputElement<boolean> {

    public static readonly styles = Styles;

    @property({attribute: "label-check"})
    private labelCheck: string;
    @property({attribute: "label-uncheck"})
    private labelUncheck: string;

    public constructor() {
        super(false);
    }

    public render(): TemplateResult {
        return html`<a @click=${this.toggle}>${this.getLabel()}</a>`;
    }

    private toggle(): void {
        this.updateValue(!this.value);
    }

    private getLabel(): string {
        return this.value
            ? this.labelUncheck
            : this.labelCheck;
    }

    protected preset(): boolean {
        return false;
    }

    public focusInput(): void {
        noop();
    }
}