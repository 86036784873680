import {InputElementType, Validation, type ValidationConfig, type ValidationResponse, WarningLevel} from "./validations";
import type {InputType} from "../commons";
import type {InputElement} from "../components/inputElement";
import {LocalDate} from "../../../common/utils/dates/localDate";

export type MinAgeConfig = ValidationConfig<{
    id: string;
    minAge?: string;
}>;

export class MinAge extends Validation {

    private constructor(messageText: string, onlyWarning: WarningLevel, public minAge?: string) {
        super(messageText, onlyWarning);
    }

    public static from(config: MinAgeConfig): MinAge {
        return new MinAge(config.message, config.warningLevel, config.validation.minAge);
    }

    protected valid(value: unknown): boolean {
        if (value === "") {
            return true;
        }

        const dateFromExpression = LocalDate.fromDateExpression(value as string);
        if (!dateFromExpression.isValid()) {
            return false;
        }
        if (this.minAge) {
            const minAgeNumber = parseInt(this.minAge);
            return dateFromExpression.calculateAge() >= minAgeNumber;
        }
        return true;
    }

    public accept<T extends InputType>(inputElement: InputElement<T>, value?: T): void {
        inputElement.setType(InputElementType.DATE);
        const validationResponse: ValidationResponse = this.checkValidity(value);

        if (!validationResponse.valid) {
            inputElement.addMessage({warningLevel: validationResponse.warningLevel, message: this.errorText});
        }
    }
}