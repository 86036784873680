import {customElement, property} from "lit/decorators.js";
import type {TemplateResult} from "lit";
import {html, LitElement} from "lit";
import Styles from "./uploadDragDrop.lit.scss";
import {unsafeSVG} from "lit/directives/unsafe-svg.js";
import {formDragDropUploadEvent} from "../formEvents";
import UPLOAD_ICON from "../../../../../resources/assets/images/upload.svg";

const DRAG_OVER_CLASS = "drag-over";


@customElement("eop-upload-drag-drop-area")
export class EopUploadDragDropArea extends LitElement {

    public static readonly styles = Styles;

    @property({type: Boolean, reflect: true})
    public disabled: boolean = false;

    private dragEnterCounter: number;

    public constructor() {
        super();
        this.dragEnterCounter = 0;
    }

    public render(): TemplateResult {
        return html`
            <div class="upload-drag-drop-area"
                 @drop=${this.dropFiles}
                 @dragover=${this.dragFiles}
                 @dragenter=${this.dragEnter}
                 @dragleave=${this.dragLeave}
            >
                ${this.renderUploadIcon()}
                <eop-msg key="DRAG_AND_DROP"></eop-msg>
            </div>`;
    }

    private renderUploadIcon(): TemplateResult {
        return html`${unsafeSVG(UPLOAD_ICON)}`;
    }

    private dragEnter(event: Event): void {
        event.preventDefault();
        this.dragEnterCounter++;
        this.classList.add(DRAG_OVER_CLASS);
    }

    private dragLeave(event: Event): void {
        event.preventDefault();
        this.dragEnterCounter--;
        if (this.dragEnterCounter === 0) {
            this.classList.remove(DRAG_OVER_CLASS);
        }
    }

    private dropFiles(event: DragEvent): void {
        event.preventDefault();
        this.classList.remove(DRAG_OVER_CLASS);
        this.dragEnterCounter = 0;

        if (!event.dataTransfer || this.disabled) {
            return;
        }

        const inputFiles: File[] = [];

        if (event.dataTransfer.items) {
            Array.from(event.dataTransfer.items).forEach(item => {
                const file = item.getAsFile();
                if (file !== null) {
                    inputFiles.push(file);
                }
            });
        } else {
            Array.from(event.dataTransfer.files).forEach(file => {
                inputFiles.push(file);
            });
        }

        this.dispatchEvent(formDragDropUploadEvent(inputFiles));
    }

    private dragFiles(event: Event): void {
        event.preventDefault();
    }
}