import type {PrimitiveType, PropertyMap} from "../../common/utils/objects";

export class FormPathAggregator {

    private path: string[];
    private closeAction: (path: string[]) => void;

    public constructor(id: string, closeAction: (path: string[]) => void) {
        this.path = [id];
        this.closeAction = closeAction;
    }

    public addScope(scopeId: string): void {
        this.path.unshift(scopeId);
    }

    public close(scopeId: string): void {
        this.path.unshift(scopeId);
        return this.closeAction(this.path);
    }
}

export type InputType = PrimitiveType | PropertyMap;