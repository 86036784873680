import {Validation, type ValidationConfig, type ValidationResponse, WarningLevel} from "./validations";
import type {InputType} from "../commons";
import type {InputElement} from "../components/inputElement";

export type PermittedValuesConfig = ValidationConfig<{
    id: string;
    permittedValues: string;
}>;

export class PermittedValues extends Validation {

    private values: string[];

    private constructor(messageText: string, onlyWarning: WarningLevel, permittedValues: string) {
        super(messageText, onlyWarning);
        this.values = permittedValues.split(",")
            .map(value => value.trim());
    }

    public static from(config: PermittedValuesConfig): PermittedValues {
        return new PermittedValues(config.message, config.warningLevel, config.validation.permittedValues);
    }

    protected valid(value: unknown): boolean {
        return typeof value === "string"
            && (value === "" || this.values.includes(value));
    }

    public accept<T extends InputType>(inputElement: InputElement<T>, value?: T): void {
        const validationResponse: ValidationResponse = this.checkValidity(value);

        if (!validationResponse.valid) {
            inputElement.addMessage({warningLevel: validationResponse.warningLevel, message: this.errorText});
        }
    }

}