import {customElement, property} from "lit/decorators.js";
import {type FormInitEvent, initStepEvent, INPUT_ELEMENT_INIT_EVENT} from "./formEvents";
import type {InputElement} from "./components/inputElement";
import {ValidationError} from "./validations/validations";
import {ScrollService} from "../../common/scroll";
import {resolve} from "../../container";
import {UnLitElement} from "../../common/elements";

export type StepData = {
    id: string;
    label: string;
    nextButtonLabel: string;
    prevButtonLabel: string;
    validate: ((silent?: boolean) => boolean);
};

@customElement("eop-form-step")
export class EopFormStep extends UnLitElement {

    @property({attribute: "next-label"})
    private nextLabel: string;
    @property({attribute: "prev-label"})
    private prevLabel: string;
    @property({attribute: "step-id"})
    private stepId: string;
    @property({attribute: "label"})
    private label: string;

    private validations: ((silent: boolean) => void)[];

    public constructor(
        private scrollService: ScrollService = resolve(ScrollService)
    ) {
        super();
        this.validations = [];
        this.addEventListener(INPUT_ELEMENT_INIT_EVENT, event => {
            const eventData = (event as FormInitEvent).detail;
            this.validations.push(eventData.validate);
        });
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.dispatchEvent(initStepEvent({
            id: this.stepId,
            label: this.label,
            nextButtonLabel: this.nextLabel,
            prevButtonLabel: this.prevLabel,
            validate: silent => this.validate(silent)
        }));
    }

    public disconnectedCallback(): void {
        this.validations = [];
        super.disconnectedCallback();
    }

    private validate(silent: boolean = false): boolean {
        const invalidElements: InputElement<any>[] = [];

        for (const validation of this.validations) {
            try {
                validation(silent);
            } catch (e) {
                if (!(e instanceof ValidationError)) {
                    throw e;
                }
                invalidElements.push(e.invalidElement);
            }
        }

        if (invalidElements.isEmpty()) {
            return true;
        }

        if (!silent) {
            this.scrollToHighestElement(invalidElements);
        }
        return false;
    }

    private scrollToHighestElement(elements: InputElement<any>[]): void {
        const element: InputElement<any> = elements.clone().sort((prev, curr) => prev.offsetTop - curr.offsetTop).first()!;
        void this.scrollService.scrollToElement(element);
        if (typeof element.focusInput !== "undefined") {
            element.focusInput();
        }
    }
}