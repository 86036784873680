import {customElement, property} from "lit/decorators.js";
import type {FormInitEvent} from "./formEvents";
import {formSubscriptionEvent, INPUT_ELEMENT_INIT_EVENT} from "./formEvents";
import {EqualHeightManager} from "../../page/elements/equalHeight";
import {resolve} from "../../container";
import {UnLitElement} from "../../common/elements";

@customElement("eop-form-section")
export class FormSection extends UnLitElement {

    @property({attribute: "section-id"})
    public sectionId: string;
    @property({attribute: "condition"})
    private condition: string;

    public constructor(private equalHeightManager: EqualHeightManager = resolve(EqualHeightManager)) {
        super();
        this.addEventListener(INPUT_ELEMENT_INIT_EVENT, event => {
            const initData = (event as FormInitEvent).detail;
            initData.path.addScope(this.sectionId);
        });
    }

    public connectedCallback(): void {
        super.connectedCallback();
        if (this.condition) {
            this.dispatchEvent(formSubscriptionEvent(this.condition, result => this.changeCondition(result)));
        }
        this.equalHeightManager.registerRegion(this);
    }

    public disconnectedCallback(): void {
        this.equalHeightManager.unregisterRegion(this);
        super.disconnectedCallback();
    }

    private changeCondition(result: boolean): void {
        this.hidden = !result;
    }
}