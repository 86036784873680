import {type DefaultValidationConfig, InputElementType, Validation, type ValidationConfig, type ValidationResponse, WarningLevel} from "./validations";
import type {InputType} from "../commons";
import type {InputElement} from "../components/inputElement";

const PHONE_NUMBER_PATTERN = new RegExp(/^[0-9\s./\-+]*$/);

export class PhoneNumber extends Validation {

    private constructor(messageText: string, onlyWarning: WarningLevel) {
        super(messageText, onlyWarning);
    }

    public static from(config: ValidationConfig<DefaultValidationConfig>): PhoneNumber {
        return new PhoneNumber(config.message, config.warningLevel);
    }

    protected valid(value: unknown): boolean {
        return typeof value === "string" && PHONE_NUMBER_PATTERN.test(value);
    }

    public accept<T extends InputType>(inputElement: InputElement<T>, value?: T): void {
        inputElement.setType(InputElementType.PHONE);
        const validationResponse: ValidationResponse = this.checkValidity(value);

        if (!validationResponse.valid) {
            inputElement.addMessage({warningLevel: validationResponse.warningLevel, message: this.errorText});
        }
    }
}