import {resolve} from "../../../container";
import {QueryParameters} from "../../../common/queryParameters";
import {type ActionResponse, ActionService, CyberSecurityOptInIDRequest} from "./actionService";
import {html, LitElement, type TemplateResult} from "lit";
import {customElement, state} from "lit/decorators.js";
import {EopOverlaySpinner} from "../../../page/elements/spinner";
import {GLOBAL} from "../../../common/globals";
import {EOP_ERROR_HANDLER, schedule} from "../../../common/utils/promises";

const OPTIN_ID_QUERY_PARAM = "optinid";
const ACTION_QUERY_PARAM = "action";

const validActions = ["confirm", "unsubscribe", "error"] as const;
type Action = (typeof validActions)[number];

@customElement("eop-cyber-security-subscriber")
export class EopCyberSecuritySubscriber extends LitElement {

    @state()
    private actionState: Action | null;

    private action: Action | null;
    private optInId: string | null;
    private spinner: EopOverlaySpinner;

    public constructor(private actionService: ActionService = resolve(ActionService),
                       private queryParameters: QueryParameters = resolve(QueryParameters)) {
        super();
        this.actionState = null;
        this.action = this.validateAction(this.queryParameters.getString(ACTION_QUERY_PARAM));
        this.optInId = this.queryParameters.getString(OPTIN_ID_QUERY_PARAM);
        this.spinner = new EopOverlaySpinner();
    }

    private validateAction(action: string | null): Action | null {
        if (action === null) {
            return null;
        }
        return (validActions as unknown as string[]).includes(action)
            ? action as Action
            : "error";
    }

    public connectedCallback(): void {
        super.connectedCallback();
        GLOBAL.bodyElement().append(this.spinner);
        if (this.optInId && this.action) {
            this.actionRequest(this.optInId);
        } else {
            this.actionState = "error";
        }
    }

    public disconnectedCallback(): void {
        this.spinner.remove();
        this.actionState = null;
        super.disconnectedCallback();
    }

    public render(): TemplateResult | null {
        if (!this.actionState) {
            return null;
        }

        return html`
            <slot name="${this.actionState}-text"></slot>
        `;
    }

    private async actionRequest(optInId: string): Promise<void> {
        let request: Promise<ActionResponse>;

        if (this.action === "confirm") {
            request = this.actionService.confirmCyberSecuritySubscriber(new CyberSecurityOptInIDRequest(optInId));
        } else if (this.action === "unsubscribe") {
            request = this.actionService.unsubscribeCyberSecuritySubscriber(new CyberSecurityOptInIDRequest(optInId));
        } else {
            request = Promise.reject(new Error("Invalid Action"));
        }

        await schedule(this.performRequest(request))
            .as("action-request");
    }

    private async performRequest(request: Promise<ActionResponse>): Promise<void> {
        try {
            const response: ActionResponse = await this.spinner.spinWhile(request);

            if (response.success) {
                this.actionState = this.action;
            } else {
                this.actionState = "error";
            }
        } catch (error) {
            EOP_ERROR_HANDLER(error, () => this.actionState = "error");
        }
    }
}
