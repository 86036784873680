import {customElement, property, queryAll} from "lit/decorators.js";
import {html, type TemplateResult} from "lit";
import Styles from "./radioButtons.lit.scss";
import {InputElement} from "./inputElement";
import {elementFrom} from "../../../common/utils/html";
import type {DirectiveResult} from "lit/directive";
import RADIO_ICON from "../../../../../resources/assets/images/radio.svg";

export type DataRadioButton = {
    value: string;
    label: string;
    preset: boolean;
};

@customElement("eop-radio-buttons")
export class EopRadioButtons extends InputElement<string> {

    public static readonly styles = Styles;

    @property({attribute: "label"})
    public label: string;
    @property({attribute: "options", type: Array})
    public options: DataRadioButton[] = [];
    @queryAll("input")
    private inputs: NodeListOf<HTMLInputElement>;

    public constructor() {
        super("");
    }

    public render(): TemplateResult {
        return html`
            <div class="input-element ${this.basicClassMap()}" ?required=${this.required}>
                <label class="label">
                    <span class="label-text">${this.label}</span>
                    ${this.labelSuffix()}
                </label>
                <div class="radio-buttons">
                    ${this.renderRadioButtons()}
                    ${this.renderValidationIcon()}
                </div>
            </div>
            ${this.renderValidationMessages()}
        `;
    }

    public focusInput(): void {
        this.inputs.item(0)?.focus();
    }

    private renderRadioButtons(): DirectiveResult[] {
        return this.options.map((option, index) => {
            return html`
                <div class="radio-button">
                    <label>
                        <input type="radio"
                               .value=${option.value}
                               ?checked=${option.value === this.value}
                               ?required=${this.required}
                               @input=${this.select}
                               name=${this.getFullId()}
                               id="${this.getFullId()}-${index}"
                        >
                        <span class="icon">${elementFrom(RADIO_ICON)}</span>
                        <span class="radio-label">${option.label}</span>
                    </label>
                </div>
            `;
        });
    }

    protected preset(): string | undefined {
        return this.options
            .findFirst(option => option.preset)
            ?.value;
    }

    protected valueEnhancements(inputValue: string): Map<string, string> {
        return new Map([["label", this.descriptorForValue(inputValue)]]);
    }

    private select(event: Event): void {
        const value = (event.currentTarget as HTMLInputElement).value;
        this.updateValue(value);
    }

    private descriptorForValue(value: string): string {
        return this.options
                .findFirst(option => value === option.value)
                ?.label
            ?? "";
    }

}